<template>
  <div>
    <div v-processsaccordion class="reader-zone">
      <slot />
    </div>
  </div>
</template>

<script setup>
onMounted(() => {
  const elements = document.querySelectorAll(
    '*[style*="background-color: #f5fcff"], *[style*="background-color:#f5fcff"]',
  );
  elements.forEach((element) => {
    element.classList.add('bg-[#f5fcff]');
    element.classList.add('dark:bg-zinc-800');
    element.classList.add('p-4');
    element.style = '';
  });

  const figures = document.querySelectorAll('figure');
  figures.forEach((item) => {
    item.style.width = '';
    item.removeAttribute('width');
  });
});

const vProcesssaccordion = {
  mounted: (el) => {
    // Replace all <smart-img> tags with the SmartImg component
    el.querySelectorAll('.accordion').forEach((node) => {
      let panel = node.nextElementSibling;
      // ...but if not found, and the accordion is wrapped in a paragraph, use the paragraph's next sibling
      if (!panel && node.parentElement && node.parentElement.tagName.toLowerCase() === 'p') {
        panel = node.parentElement.nextElementSibling;
      }
      panel.style.display = 'none';
      node.addEventListener('click', () => {
        node.classList.toggle('active');
        if (panel.style.display === 'block') {
          panel.style.display = 'none';
        } else {
          panel.style.display = 'block';
        }
      });
    });
  },
};
</script>

<style scoped>
:deep(.reader-zone ul:has(li.book_button)) {
  list-style: none;
  padding: 0;
}
:deep(.reader-zone .authors-container) {
  img {
    @apply my-0 !important;
  }
  @apply leading-normal;
}
:deep(.reader-zone) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply leading-normal;
    @apply text-[#1B1E26] dark:text-[#F5F5F5];
    @apply text-left;
  }

  h1 {
    @apply text-8xl;
    @apply font-semibold;
    @apply text-6xl;
  }

  h2 {
    @apply mt-10;
    @apply mb-5;
    @apply text-4xl;
    @apply font-[700];
  }

  h3 {
    @apply mt-6;
    @apply mb-2;
    @apply text-3xl;
  }

  h4 {
    @apply mt-6;
    @apply mb-2;
    @apply font-bold;
    @apply text-[1.35rem];
  }

  h5 {
    @apply mt-6;
    @apply mb-2;
    @apply font-bold;
    @apply text-[1.25rem];
  }

  blockquote {
    @apply italic;
    p {
      span {
        @apply !text-lg;
      }
    }
  }

  a {
    @apply font-semibold;
    @apply cursor-pointer;
    @apply underline;
  }

  .book_button {
    @apply bg-gradient-to-r from-ts_blue_to to-ts_blue_from;
    @apply rounded-full;
    @apply text-white;
    @apply my-4;
    @apply text-center;
    @apply cursor-pointer;
    @apply text-xl;
    @apply w-fit;
    @apply mx-auto;
    @apply shadow-[0_8px_15px_0_rgba(24,157,220,0.2)];
    @apply capitalize;
    @apply flex items-center gap-2;

    ul {
      @apply list-none;
    }

    p {
      @apply my-0;
      @apply text-white;
      @apply text-center;
      @apply max-w-3xl;
      @apply mx-auto;
      @apply capitalize;
    }
    
    > a,
    > p > a {
      @apply px-8 py-2;
      @apply block;
    }

    a {
      @apply text-white;
      @apply text-center;
      @apply no-underline;
    }
  }
  .book_button:hover {
    @apply shadow-[0_8px_15px_0_rgba(24,157,220,0.4)];
    @apply transition-all;
  }
  p {
    @apply mt-2;
    @apply mb-[20px];
    @apply text-justify;
    @apply leading-[1.8rem];
  }

  ul {
    @apply list-disc;
    @apply pl-6;
    @apply mb-4;
  }

  ol {
    @apply list-decimal;
    @apply pl-6;
    @apply mb-4;
  }

  li {
    @apply mb-2;
  }

  img {
    @apply rounded-lg;
    @apply mx-auto;
    @apply my-4;
  }

  figcaption {
    @apply text-sm;
    @apply text-[#454854];
    @apply dark:text-[#F5F5F5];
  }

  @apply text-xl;
  @apply leading-8;
  @apply text-justify;
  @apply text-[#454854];
  @apply dark:text-[#F5F5F5];

  iframe {
    width: 100%;
    height: 500px;
  }

  .accordion {
    @apply bg-[#edfaff] text-[#1b1e26] cursor-pointer p-[18px] w-full text-left border-none outline-none transition-all duration-200 rounded mt-3;
    @apply dark:bg-[#242731] dark:text-[#F5F5F5];
  }
  .accordion:after {
    content: '\002B';

    @apply text-xl text-[#1b1e26] float-right ml-[5px];
    @apply dark:text-[#F5F5F5];
  }

  .active:after {
    content: '\2212'; /* Unicode character for "minus" sign (-) */
  }

  .accordion.active {
    @apply bg-[#ddf4ff] dark:bg-[#242731];
  }

  .panel {
    @apply px-4 py-2;
  }
}
</style>
